import React from 'react';
import './Bio.css';

const Bio = () => {
    return (
        <div className="bio-container">
            <p>Hello, I'm Oleksandr Tsukanov.
                I was born in Ukraine 🇺🇦 the bravest nation in the world.
                <br/><br/>
                I like to create digital things and
                make world 🌍 better.
                With over 7 years of experience, I am a Software Developer, navigating the full
                development lifecycle of applications.
                <br/><br/>
                During my career I have had the chance to work in many tech companies like: Ciklum, GlobalLogic and SoftServe.
                <br/><br/>
                Feel free to <a href="mailto:contact@alexandertsukanov.com">contact</a> with me or connect with me on <a href="https://www.linkedin.com/in/alexandertsukanov/">LinkedIn</a>
            </p>
        </div>
    );
}

export default Bio;
